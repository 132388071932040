import styled from 'styled-components'

export const StyledDesc = styled.p`
  margin-top: var(--spacing-legacy-5);
  line-height: 1.2;
`

const StyledInput = styled.div`
  width: 100%;

  .prefix {
    margin-right: var(--spacing-legacy-8);
  }

  input {
    width: 100%;
    background-color: inherit;
    font-size: var(--font-size-14);

    ::placeholder {
      color: var(--color-gray-128);
    }

    :disabled {
      cursor: not-allowed;

      ::placeholder {
        opacity: 0.3;
      }
    }

    @media (max-width: 767px) {
      font-size: var(--font-size-16);
    }
  }
`

export default StyledInput
